export const monthMapping = {
  'jan': 1,
  'feb': 2,
  'mar': 3,
  'apr': 4,
  'may': 5,
  'jun': 6,
  'jul': 7,
  'aug': 8,
  'sep': 9,
  'oct': 10,
  'nov': 11,
  'dec': 12,
};

export const monthTranslations = {
  jan: 'январь',
  feb: 'февраль',
  mar: 'март',
  apr: 'апрель',
  may: 'май',
  jun: 'июнь',
  jul: 'июль',
  aug: 'август',
  sep: 'сентябрь',
  oct: 'октябрь',
  nov: 'ноябрь',
  dec: 'декабрь',
};

export const progressPhotosStore = {
  2019: {
    dec: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
    ],
    oct: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
    ],
  },
  2020: {
    jan: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
      '13.jpg',
      '14.jpg',
      '15.jpg',
      '16.jpg',
      '17.jpg',
      '18.jpg',
      '19.jpg',
      '20.jpg',
      '21.jpg',
      '22.jpg',
      '23.jpg',
    ],
    feb: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
    ],
    mar: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
    ],
    apr: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
      '13.jpg',
      '14.jpg',
      '15.jpg',
      '16.jpg',
      '17.jpg',
      '18.jpg',
      '19.jpg',
      '20.jpg',
      '21.jpg',
      '22.jpg',
      '23.jpg',
      '24.jpg',
      '25.jpg',
    ],
    may: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
    ],
    jun: [
      '1.png',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
    ],
    jul: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
    ],
    aug: [
      '1.png',
      '2.jpg',
      '3.jpg',
    ],
    sep: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
    ],
    oct: [
      '1.jpg',
      '2.jpg',
    ],
    dec: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
    ],
  },
  2021: {
    jan: [
      '1.png',
      '2.png',
      '3.png',
      '4.png',
    ],
    feb: [
      '1.jpg',
      '2.jpg',
      '3.jpg',
    ],
  },
};
