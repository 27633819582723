import React, { useState, useRef } from 'react';
import Layout from '../layout';
import Container from '../container';
import { LineBottom } from '../lines';

function ProgressReport({ title, date, images, light, children, collapsed })
{
  const photosRef = useRef(null);
  const [activePhoto, setActivePhoto] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const toggleActive = el => el.classList.toggle('active');

  const changePhoto = offset =>_e => {
    let newIdx = activePhoto + offset;

    if (newIdx >= images.length || newIdx < 0)
      return;
    else {
      toggleActive(photosRef.current.children[activePhoto]);
      toggleActive(photosRef.current.children[newIdx]);
      setActivePhoto(newIdx);
    }
  };

  const toggleCollapse = () => { setIsCollapsed(!isCollapsed); };

  return (
    <Layout className={`progress-section ${ light ? 'section-bg-light' : 'section-bg-dark'}`}>
      { children }

      <Container>
        <div className="title" onClick={toggleCollapse}>
          <h2>{ title }</h2>
          <span>{ date }</span>

          <div className={`collapse-flag ${isCollapsed ? 'collapse-flag--is-open' : ''}`}>
            <div className='arrow' />
          </div>

          <LineBottom />
        </div>


        {
          !isCollapsed && (
            <div className="photos-wrapper--outter">
              <div className="prev" onClick={ changePhoto(-1) }>
                <img src="/icons/arrow.svg" alt="button, previous" />
              </div>

              <div className="photos-wrapper--inner">
                <img src={ images[0] } alt="" />

                <div className="photos">
                  <div className="referer" ref={ photosRef }>
                    {
                       images.map((img, idx) =>
                         <img
                           key={idx}
                           src={ img }
                           alt=""
                           className={ idx === activePhoto ? 'active' : '' }
                         />)
                    }
                  </div>

                  <div className="dots">
                    { images.map((img, idx) =>
                        <div key={idx} className={ idx === activePhoto ? 'dot active' : 'dot' } />) }
                  </div>
                </div>
              </div>

              <div className="next" onClick={ changePhoto(1) }>
                <img src="/icons/arrow.svg" alt="button, next" style={{ transform: 'rotate(180deg)' }} />
              </div>
            </div>
          )
        }
      </Container>
    </Layout>
  );
}

ProgressReport.defaultProps = {
  collapsed: true,
};

export default ProgressReport;
