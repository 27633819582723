import React from 'react';

const LineTop = ({ style }) => (<span className="line horizontal top" style={style} />);

const LineBottom = ({ style }) => (<span className="line horizontal bottom" style={style} />);

const LineLeft = ({ style }) => (<span className="line vertical left" style={style} />);

const LineRight = ({ style }) => (<span className="line vertical right" style={style} />);

export {
  LineTop,
  LineBottom,
  LineLeft,
  LineRight
};