import '../styles/index.scss';
import React, { Component } from 'react';
import SEO from '../components/seo';
import Footer from '../components/footer';
import Header from '../components/header';
import ProgressReport from '../components/progress-report';
import {
  monthMapping,
  monthTranslations,
  progressPhotosStore
} from '../store/state/progress';


class Progress extends Component {
  state = { menuBgVisible: false };

  toggleBg = () => {
    this.setState({ menuBgVisible: !this.state.menuBgVisible });
    const bodyOverflow = getComputedStyle(document.documentElement).overflow;
    document.documentElement.style.overflow =
      bodyOverflow === 'hidden' ? 'visible' : 'hidden';
  };

  render() {
    const { menuBgVisible } = this.state;

    const sortYearsCallback = ([a_year, _a_months], [b_year, _b_months]) => {
      const aYearInt = parseInt(a_year);
      const bYearInt = parseInt(b_year);

      if (aYearInt > bYearInt) {
        return -1;
      } else if (aYearInt < bYearInt) {
        return 1;
      } else {
        return 0;
      }
    };

    const sortMonthCallback = ([a_month, _a_photos], [b_month, _b_photos]) => {
      if (monthMapping[a_month] > monthMapping[b_month]) {
        return -1;
      } else if (monthMapping[a_month] < monthMapping[b_month]) {
        return 1;
      } else {
        return 0;
      }
    };

    const list = progressPhotosStore;
    const sortedYears = Object.entries(progressPhotosStore)
      .sort(sortYearsCallback)
      .map(([year, months]) => {
        const sortedMonths =
          Object
            .entries(months)
            .sort(sortMonthCallback)
            .map(([month, images]) => ([
              month,
              images.map(img_name => `/progress/${year}/${month}/${img_name}`)
            ]));

        return [
          year,
          sortedMonths
        ];
      });

    const headerYear = sortedYears[0][0];
    const headerMonth = sortedYears[0][1].splice(0, 1)[0];
    const headerMonthName = monthTranslations[headerMonth[0]];
    const headerMonthPhotos = headerMonth[1];

    return (
      <div className="progress-page page">
        <SEO title='Ход строительства' />
        <div className={`menu-bg ${menuBgVisible ? 'active' : ''}`} />

        <ProgressReport
          title={headerMonthName || ''}
          date={headerYear}
          images={headerMonthPhotos}
          collapsed={false}
        >
          <Header
            toggleBg={ this.toggleBg }
            active={ menuBgVisible }
            style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }} />
        </ProgressReport>

        {
          (() => {
            let isWhite = true;

            return sortedYears.map(([year, months]) => (
              months.map(([month_name, images]) => {
                const theme = { light: isWhite, dark: !isWhite };
                isWhite = !isWhite;

                return (
                  <ProgressReport
                    key={`${year}-${month_name}`}
                    title={monthTranslations[month_name]}
                    date={year}
                    images={images}
                    {...theme}
                  />
                );
              })
            ))
          })()
        }

        <Footer />
      </div>
    );
  }
};

export default Progress;
